<template>
  <k-crud-layout :filters.sync="filters">
    <template #header>
      {{ microservice.name }}
    </template>
    <template #filters="{ attrs, on }">
      <MicroserviceFilter v-bind="attrs" v-on="on" />
    </template>
    <template #view.list>
      <Resource
        :show-request="showHandler"
        :index-request="indexHandler"
        :update-request="updateHandler"
        :delete-request="deleteHandler"
        :meta="{
          name: $tc('connector.title', 1),
          namePlural: $tc('connector.title', 2),
        }"
        :parameters="filters"
        :form-component="
          () => import('../../components/forms/ConnectorForm.vue')
        "
        :table-content="tableContent"
        :model-type="modelType"
        :can-add="false"
        :can-delete="can('client.connector.all')"
        :can-update="can('client.connector.all')"
      />
    </template>
  </k-crud-layout>
</template>

<script lang="js">
import Resource from '@/components/Resource.vue';
import eventBus from '@/eventBus.js';
import Connector from '@/application/models/connector.js';
import { microservice, remove, update, show } from '@/api/endpoints/connector.js';
import MicroserviceFilter from '@/components/microservice/MicroserviceFilter.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';
import { mapGetters } from "vuex";

export default {
  name: 'ConnectorResource',
  mixins: [querySyncMixin],
  components: {
    KCrudLayout,
    Resource,
    MicroserviceFilter,
  },
  data: () => ({
    filters: { isActive: 1 },
  }),
  props: {
    microservice: {
      required: true,
      type: Object,
    }
  },
  computed: {
    ...mapGetters("profile", ["can"]),
    modelType: () => Connector,
    tableContent() {
      return [
        {
          text: this.$t('connector.fields.name'),
          align: 'left',
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('connector.fields.clientName'),
          align: 'left',
          sortable: false,
          value: 'clientName',
        },
        {
          text: this.$t('connector.fields.version'),
          align: 'left',
          sortable: false,
          value: 'version',
        },
        {
          text: this.$t('connector.fields.isActive'),
          align: 'left',
          sortable: true,
          value: 'isActive',
          columnType: 'checkbox',
        },
        {
          text: this.$t('connector.fields.clientIsActive'),
          align: 'left',
          sortable: true,
          value: 'clientIsActive',
          columnType: 'checkbox',
        },
      ]
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'home' },
        text: this.$t('global.dashboard'),
      },
      {
        exact: true,
        to: { name: 'connector.index' },
        text: this.$tc('connector.title', 2),
      },
      {
        exact: true,
        to: { name: 'connector.microservice' },
        text: this.microservice.name,
      },
    ]);
  },
  methods: {
    indexHandler(...args) {
      return microservice(this.microservice.id, ...args);
    },
    showHandler: show,
    deleteHandler: remove,
    updateHandler: update,
  }
}
</script>
