<template>
  <k-field-group language-prefix="microservice">
    <k-sidebar
      v-bind="$attrs"
      v-on="$listeners"
      :save-button-text="$t('global.applyFilter')"
      :title="
        $t('global.filterTitle', { module: $tc('microservice.title', 2) })
      "
      fixed
      right
      temporary
      width="300"
      @click:confirm="handleFilterClick"
    >
      <KCheckbox
        v-model="isActive"
        @change="setActiveFilter()"
        field="fields.isActive"
      />
      <KCheckbox
        v-model="isNotActive"
        @change="setActiveFilter()"
        field="fields.isNotActive"
      />
    </k-sidebar>
  </k-field-group>
</template>

<script>
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";
import KSidebar from "@/components/layout/KSidebar.vue";
import KCheckbox from "@/components/crud/fields/KCheckbox.vue";

export default {
  name: "MicroserviceFilter",
  components: {
    KCheckbox,
    KSidebar,
    KFieldGroup,
  },
  inheritAttrs: false,
  props: {
    filters: {
      type: Object,
    },
    instructionTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {},
      isActive: true,
      isNotActive: false,
    };
  },
  watch: {
    filters: {
      handler(values) {
        this.form = { ...this.form, ...values };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleFilterClick() {
      this.$emit("update:filters", this.form);
      this.$emit("input", false);
    },
    setActiveFilter() {
      this.form.isActive =
        this.isActive && this.isNotActive
          ? undefined
          : this.isActive
          ? 1
          : this.isNotActive
          ? 0
          : undefined;
    },
  },
};
</script>
